<template>
  <template v-if="!options || !options.nationalspieler || !activeAnswers">
    <preloader />
  </template>
  <template v-else>
  <div class="clearfix">
    <div class="row">
      <div class="col-md-6"><h4>{{ options.ausbildung.infos.title }}</h4>
      </div>
      <div class="col-md-6">
        <button type="button" @click="showInput5 = 1" class="btn btn-secondary float-end mb-3" v-if="$store.state.currentApplicationClosedState === false && showInput5 === 0 && (activeAnswers.ausbildung === undefined || activeAnswers.ausbildung.length === 0) ">
          <font-awesome-icon :icon="['far', 'plus']"/>
          <span class="ms-3">Station hinzufügen</span>
        </button>
      </div>
    </div>
  </div>
  <Modal v-if="showInput5 === 1">
    <div class="container">
      <div class="card p-lg-5 p-3">
        <h2 class="mb-4">{{ options.ausbildung.infos.title }} hinzufügen</h2>
        <div class="description mb-4" v-html="options.ausbildung.infos.description"></div>
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" v-model="valuesBildung.schwerpunkt" class="form-control" placeholder="Schwerpunkt">
              <label for="floatingInput">Schwerpunkt</label>
            </div>
            <div class="form-floating mb-3">
              <input type="text" v-model="valuesBildung.institution" class="form-control" placeholder="Institution">
              <label for="floatingInput">Institution</label>
            </div>
          </div>
          <div class="col-md-12 col-lg-6">

            <div class="form-floating mb-3">
              <perfect-scrollbar>
                <div v-for="(option, key) in options.ausbildung.options" v-bind:key="key">
                  <template v-if="option.is_headline === '1'">
                    <label class="labelHeadline"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key">{{ option.question }}</label>
                  </template>
                  <template v-else>
                  <input :value="option.id" v-model="valuesBildung.answer_id " type="radio" class="btn-check"
                         :id="'option'+key" autocomplete="off">
                  <label class="btn btn-outline-primary"
                         style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                         :for="'option'+key">{{ option.question }}</label>
                  </template>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <button class="btn btn-danger mb-3" type="button" @click="showInput5 = 0">Abbrechen</button>
          </div>
          <div class="col-6">
            <button @click="postValues('ausbildung',valuesBildung); showInput5 = 0" type="button"
                    class="btn btn-secondary float-end mb-3"  v-if="valuesBildung.answer_id && valuesBildung.schwerpunkt && valuesBildung.institution"  >
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <div class="clearfix"></div>
  <div class="table-responsive">
    <table class="table table-striped table-values">
      <thead  v-if="activeAnswers.ausbildung">
      <tr>
        <th class="del"></th>
        <th>Schwerpunkt</th>
        <th>Institution</th>
        <th>Ausbildungsabschluss</th>
        <th class="text-center" style="width:130px" >Nachweis</th>
        <th class="points-end">Punkte</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="entry in activeAnswers.ausbildung.items" v-if="activeAnswers.ausbildung">
        <td>
          <span v-if="$store.state.currentApplicationClosedState === false" class="delEntry btn btn-small btn-danger" @click='delEntry(entry.id)'>X</span>
          <EntryComment :entry="entry" />
        </td>
        <td>{{ entry.schwerpunkt }}</td>
        <td>{{ entry.institution }}</td>
        <td>{{ entry.question }}</td>
        <td>
          <template  v-if="$store.state.currentApplicationClosedState === false && calcTypes && calcTypes.ausbildung && calcTypes.ausbildung.nachweis_erforderlich === 'yes'">
            <button @click="startedUpload = entry.id" class="btn btn-warning w-100"><font-awesome-icon style="cursor: pointer" :icon="['far', 'plus']"/></button>
            <template  v-for="(nw,index) in entry.nachweis">
              <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                      style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span v-else> {{index+1}}</span>
              </button>
            </template>
          </template>
        </td>
        <td>{{ entry.points }}</td>
      </tr>
      <tr v-else>
        <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
      </tr>
      </tbody>
    </table>
  </div>
    <div class="clearfix mt-5">
      <div class="row">
        <div class="col-md-6"><h4 v-if="options && options.trainerausbildung">{{ options.trainerausbildung.infos.title }}</h4>

        </div>
        <div class="col-md-6">
          <button type="button" @click="showInputT = 1" class="btn btn-secondary float-end mb-3" v-if="$store.state.currentApplicationClosedState === false && showInputT === 0 && options.trainerausbildung && options.trainerausbildung.options.length != Object.keys(activeTrainerAnswers).length">
            <font-awesome-icon :icon="['far', 'plus']"/>
            <span class="ms-3">Station hinzufügen</span>
          </button>
        </div>
      </div>
    </div>
    <Modal v-if="showInputT === 1">
      <div class="container">

        <div class="card p-lg-5 p-3">

          <h2 class="mb-4">{{ options.trainerausbildung.infos.title }} hinzufügen</h2>
          <div class="description mb-4" v-html="options.trainerausbildung.infos.description"></div>
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesT.schwerpunkt" class="form-control" placeholder="Schwerpunkt">
                <label for="floatingInput">Schwerpunkt</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesT.institution" class="form-control" placeholder="Institution">
                <label for="floatingInput">Institution</label>
              </div>
            </div>
            <div class="col-md-12 col-lg-6" v-if="options.trainerausbildung">
              <div class="form-floating mb-3">
                <perfect-scrollbar>
                  <div v-for="(option, key) in options.trainerausbildung.options" v-bind:key="key">
                    <template v-if="activeTrainerAnswers[option.id] === undefined">
                      <template v-if="option.is_headline === '1'">
                        <label class="labelHeadline"
                               style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                               :for="'option'+key">{{ option.question }}</label>
                      </template>
                      <template v-else>
                      <input :value="option.id" v-model="valuesT.answer_id" type="radio" class="btn-check"
                           :id="'option'+key" autocomplete="off">
                    <label class="btn btn-outline-primary"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key">{{ option.question }}</label>
                      </template>
                    </template>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <button class="btn btn-danger mb-3" type="button" @click="showInputT = 0">Abbrechen</button>
            </div>
            <div class="col-6">
              <button @click="postValues('trainerausbildung',valuesT); showInputT = 0" type="button"
                      class="btn btn-secondary float-end mb-3"  v-if="valuesT.answer_id && valuesT.schwerpunkt && valuesT.institution" >
                Speichern
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <div class="clearfix"></div>
    <div class="table-responsive" v-if="options.trainerausbildung">
      <table class="table table-striped table-values">
        <thead  v-if="activeAnswers.trainerausbildung">
        <tr>
          <th class="del"></th>
          <th>Schwerpunkt</th>
          <th>Institution</th>
          <th>Ausbildungsabschluss</th>
          <th class="text-center" style="width:130px" >Nachweis</th>
          <th class="points-end">Punkte</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="entry in activeAnswers.trainerausbildung.items" v-if="activeAnswers.trainerausbildung">
          <td>
            <span v-if="$store.state.currentApplicationClosedState === false" class="delEntry btn btn-small btn-danger" @click='delEntry(entry.id)'>X</span>
            <EntryComment :entry="entry" />
          </td>
          <td>{{ entry.schwerpunkt }}</td>
          <td>{{ entry.institution }}</td>
          <td>{{ entry.question }}</td>
          <td>
            <template  v-if="$store.state.currentApplicationClosedState === false && calcTypes && calcTypes.trainerausbildung && calcTypes.trainerausbildung.nachweis_erforderlich === 'yes'">
              <button @click="startedUpload = entry.id" class="btn btn-warning w-100"><font-awesome-icon style="cursor: pointer" :icon="['far', 'plus']"/></button>

              <template  v-for="(nw,index) in entry.nachweis">
                <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                        style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                  <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span v-else> {{index+1}}</span>
                </button>
              </template>
            </template>
          </td>
          <td>{{ entry.points }}</td>
        </tr>
        <tr v-else>
          <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
        </tr>
        </tbody>
      </table>
    </div>
  <div class="clearfix mb-3 mt-5">
    <div class="row">
      <div class="col-md-6"><h4>{{ options.weiterbildung.infos.title }}</h4></div>
      <div class="col-md-6">
        <button type="button" @click="showInput6 = 1" class="btn btn-secondary float-end mb-3" v-if="$store.state.currentApplicationClosedState === false && showInput6 === 0">
          <font-awesome-icon :icon="['far', 'plus']"/>
          <span class="ms-3">Station hinzufügen</span></button>
      </div>
    </div>
  </div>
  <Modal class="inputBlock mb-3" v-if="showInput6 === 1">
    <div class="container">
      <div class="card p-lg-5 p-3">
        <h2 class="mb-4">{{ options.weiterbildung.infos.title }} hinzufügen</h2>
        <div class="description mb-4" v-html="options.weiterbildung.infos.description"></div>
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <div class="form-floating mb-3">
              <input type="text" v-model="valuesWeiterbildung.schwerpunkt" class="form-control"
                     placeholder="Schwerpunkt">
              <label for="floatingInput">Schwerpunkt</label>
            </div>
            <div class="form-group mb-3">
              <template v-if="valuesWeiterbildung.institution">
                <label>Institution</label>
                <input type="text" v-model="valuesWeiterbildung.institution" class="form-control"
                       placeholder="Institution">
              </template>
              <template v-else>
                <label>Institution</label>
                <select class="form-select" v-model="valuesWeiterbildung.institution_id">
                  <option v-for="institution in institutions" :value="institution.id">{{ institution.title }}</option>
                </select>


                <template v-if="valuesWeiterbildung.institution_id === 'new'">
                  <input class="form-control mt-2 small" type="text" v-model="valuesWeiterbildung.new_institution" placeholder="Neue Institution eintragen" /></template>
              </template>
            </div>
            <div class="form-floating mb-3">
              <input type="number" step="1" min="0" max="999" v-model="valuesWeiterbildung.lerneinheiten" class="form-control" placeholder="Lerneinheiten">
              <label for="floatingInput">Lerneinheiten</label>
            </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <div class="form-floating mb-3">
              <perfect-scrollbar>
                <div v-for="(option, key) in options.weiterbildung.options" v-bind:key="key">
                  <template v-if="option.is_headline === '1'">
                    <label class="labelHeadline"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key">{{ option.question }}</label>
                  </template>
                  <template v-else>
                  <input :value="option.id" v-model="valuesWeiterbildung.answer_id" type="radio" class="btn-check"
                         :id="'option'+key" autocomplete="off">
                  <label class="btn btn-outline-primary"
                         style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                         :for="'option'+key">{{ option.question }}</label>
                  </template>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <button class="btn btn-danger mb-3" type="button" @click="showInput6 = 0">Abbrechen</button>
          </div>
          <div class="col-6">
            <button @click="postValues('weiterbildung' ,valuesWeiterbildung);  showInput6 = 0" type="button"
                    class="btn btn-secondary float-end mb-3"  v-if="valuesWeiterbildung.answer_id && valuesWeiterbildung.schwerpunkt && valuesWeiterbildung.institution_id  && valuesWeiterbildung.lerneinheiten" >
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <div class="clearfix"></div>
  <div class="table-responsive">
    <table class="table table-striped table-values">
      <thead v-if="activeAnswers.weiterbildung">
      <tr>
        <th class="del"></th>
        <th>Schwerpunkt</th>
        <th>Relevante Themenfelder</th>
        <th class="text-center" style="width:130px" v-if="calcTypes && calcTypes.weiterbildung && calcTypes.weiterbildung.nachweis_erforderlich === 'yes'">Nachweis</th>
        <th class="points-end">Lerneinheiten</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="OuterEntry in activeAnswers.weiterbildung.infos"  v-if="activeAnswers.weiterbildung && activeAnswers.weiterbildung.infos">


      <tr v-for="entry in OuterEntry.items">
        <td>
          <span v-if="$store.state.currentApplicationClosedState === false" class="delEntry btn btn-small btn-danger" @click='delEntry(entry.id)'>X</span>
          <EntryComment :entry="entry" />
        </td>
        <td>{{ entry.schwerpunkt }}</td>
        <td>{{ entry.question }}</td>
        <td>
          <template  v-if="$store.state.currentApplicationClosedState === false && calcTypes && calcTypes.weiterbildung && calcTypes.weiterbildung.nachweis_erforderlich === 'yes'">
            <button @click="startedUpload = entry.id" class="btn btn-warning w-100"><font-awesome-icon style="cursor: pointer" :icon="['far', 'plus']"/></button>
            <template  v-for="(nw,index) in entry.nachweis">
              <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                      style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span v-else> {{index+1}}</span>
              </button>
            </template>
          </template>
        </td>
        <td>{{ entry.lerneinheiten }}</td>
      </tr>
        <tr><td></td><td colspan="2"><span style="font-weight: bold;">Institution: {{OuterEntry.title}}</span></td><td style="text-align: right;" colspan="2"><span style="font-weight: bold;">{{OuterEntry.points}} Punkt(e) / {{OuterEntry.le}} LE</span></td></tr>
      </template>
      <tr v-else>
        <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
      </tr>
      </tbody>
    </table>
  </div>
    <Modal v-if="activeFile && activeFile.content">

      <div class="container">
        <div class="card" style="padding:24px; max-width: 80%">
          <div class="row">
            <div class="col-lg-6"><button @click="deleteFile()" class="btn btn-danger">Datei löschen</button></div>
            <div class="col-lg-6"><button @click="activeFile = null;" class="btn btn-success float-end">Vorschau schließen</button></div>
          </div>


          <perfect-scrollbar style="max-height: 80vh">
            <vue-pdf-embed v-if="activeFile.contentType === 'application/pdf'" :source="'data:'+activeFile.contentType+';base64,'+activeFile.content" type="" />
            <embed v-else :src="'data:'+activeFile.contentType+';base64,'+activeFile.content">
          </perfect-scrollbar>
        </div>
      </div>


    </Modal>
    <Modal v-if="startedUpload" >
      <div class="container">
        <div class="card" style="padding:24px; max-width: 80%">
          <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-6"><button @click="startedUpload = null;" class="btn btn-success float-end">Upload schließen</button></div>
          </div>
          <UploadComponent :entryId="startedUpload"/>
        </div>
      </div>

    </Modal>
  </template>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {ref} from "vue";
import UploadComponent from "@/components/UploadComponent";
import Preloader from "@/components/Preloader";
import {useToast} from 'vue-toastification'
import VuePdfEmbed from "vue-pdf-embed";
import EntryComment from "@/components/application/EntryComment";
export default {
  name: 'ApplicationEducations',
  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },
  components: {
    UploadComponent,
    Preloader,
    VuePdfEmbed,
    EntryComment
  },
  props: {
    calcTypes: {}
  },
  data() {
    return {
      startedUpload: null,
      activeFile: null,
      showUpload: false,
      loadingFile: null,
      currentEntry: null,
      nav: 1,
      options: null,
      punkte: 0,
      ergebnis: 0,
      values: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verein: null
      },
      valuesNat: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verband: null
      },
      valuesSpieler: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verein: null
      },
      valuesSpielerNat: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verband: null
      },
      valuesBildung: {
        answer_id: null,
        schwerpunkt: null,
        institution: null,
      },
      valuesT: {
        answer_id: null,
        schwerpunkt: null,
        institution: null,
      },
      valuesWeiterbildung: {
        answer_id: null,
        schwerpunkt: null,
        institution: null,
      },
      activeCustomer: {},
      activeAnswers: null,
      activeTrainerAnswers: {},
      showInput: 0,
      showInput1: 0,
      showInput4: 0,
      showInput42: 0,
      showInput5: 0,
      showInput6: 0,
      showInputT: 0,
      institutions: [],
    }
  },
  computed: {
    ...mapGetters(["getUser", "getApiUrl", "getApiAuth"]),
  },
  created() {
    this.getOptions()
    this.getInstitutions()
    const headers = {"crmAuth": localStorage.getItem("dfbToken")};
    axios.get(this.getApiUrl + 'customer/' + this.getUser.crm_customer_id, {headers})
      .then(response => {
        let shortResponse = response.data.result;
        this.activeCustomer = shortResponse.data;
        if (this.activeCustomer.id) {
          this.getAnswers();
        }
      })
      .catch(error => {

      })
    window.addEventListener("triggerReloadTask", this.getAnswers)
  },
  methods: {
    getInstitutions() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'plugins/dfb/institutions', {headers})
        .then(response => {
          let shortResponse = response.data.result;
          this.institutions = shortResponse.data;
        })
        .catch(error => {
          this.$log.debug(error)
        })
    },
    startUploadInline(entryId) {
      this.startedUpload = entryId;
    },
    openFile(entry, index) {

      this.loadingFile = entry.id+index;
      const headers = {"crmAuth":localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'plugins/dfb/readFile/' + entry.id+'/'+index, {headers}).then((response) => {
        let shortReport = response.data.result;
        this.activeFile = {entryId: entry.id, index: index, content: shortReport.data.content, contentType: shortReport.data.contentType};
        this.loadingFile = null;
      }).catch((e) => {

        this.loadingFile = null;
      });
    },
    deleteFile() {


      this.deletingFile = this.activeFile.id;
      const headers = {"crmAuth":localStorage.getItem("dfbToken")};
      axios.delete(this.getApiUrl + 'plugins/dfb/removeFile/' + this.activeFile.entryId+'/'+this.activeFile.index, {headers}).then((response) => {
        this.deletingFile = null;
        this.activeFile = null;
        const triggerReloadTask = new CustomEvent('triggerReloadTask');
        window.dispatchEvent(triggerReloadTask);
      }).catch((e) => {

        this.deletingFile = null;
        this.activeFile = null;
        const triggerReloadTask = new CustomEvent('triggerReloadTask');
        window.dispatchEvent(triggerReloadTask);
      });
    },
    showNav: function (index) {
      this.nav = index
    },
    delEntry(entryId) {

      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.delete(this.getApiUrl + 'plugins/dfb/answers/' + entryId, {headers})
        .then(response => {
          const toast = useToast();
          toast.success("Eintrag wurde gelöscht");
          this.getAnswers();
        })
        .catch(error => {

        })
    },
    prepareTrainer() {


      if (this.activeAnswers.trainerausbildung && this.activeAnswers.trainerausbildung.items) {

        let a;
        let b;
        let c= {};
        for(a in this.activeAnswers.trainerausbildung.items) {

          b = this.activeAnswers.trainerausbildung.items[a];
          c[b.answer_id] = true;

        }
        this.activeTrainerAnswers = c;
      }

    },
    getAnswers() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'plugins/dfb/answers?requestId='+this.$store.state.currentApplication.id, {headers})
        .then(response => {
          this.$log.debug("answeers: ", response);
          let shortResponse = response.data.result;
          this.$log.debug("short", shortResponse);
          this.activeAnswers = shortResponse.data;
          this.prepareTrainer();
        })
        .catch(error => {
          this.$log.debug(error)
        })
    },
    postValues: function (postType, values) {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.post(this.getApiUrl + 'plugins/dfb/' + postType, values, {
        headers
      })
        .then(response => {
          let shortReport = response.data.result;
          this.activeAnswers = shortReport.data;
          this.prepareTrainer();
          this.showInput = 0;
          this.$store.dispatch('setToastsDone');
        })
        .catch(error => {
          this.$store.dispatch('handleApiError', error);
        })
    },
    getOptions: function () {
      console.log("loading options");
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + 'plugins/dfb/config?requestId='+this.$store.state.currentApplication.id, {headers})
        .then(response => {
          this.options = response.data.result.data;

          console.log("options loaded", this.options);
        })
        .catch(error => {
          this.$log.debug(error)
        })
    }
  },
}

</script>
