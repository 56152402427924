<template>
  <MainHeader headline="Voraussetzungen" :text="(activeLicense) ? activeLicense.name : 'Aktuelle Dokumentenvorlagen'"/>
  <div class="item-page">
    <div class="container pt-4" style="min-height: 90vh">
      <div class="row" v-if="!activeLicense">
        <template  v-for="current in licenses">
        <div class="col-12 col-md-6 col-lg-4 mb-1" v-if="current.state !== 'closed'">

          <div @click="$router.push({name:'voraussetzungenDetails', params:{id:current.alias}})" class="card bl vor"
               v-if="current && current.name"
               style="min-height: 250px !important;">
            <div class="card-body" :style="{background:'url(https://cms.dfb-trainerbewerbung.de/images/lizenzen/'+current.public_img+')'}">

                  <h6 style="margin-bottom: 0;">{{ current.name }}</h6>

            </div>
          </div>
        </div></template>
      </div>
      <div class="row">
        <div class="col-12 pt-4">
          <template v-if="activeLicense">
            <div class="card-body">
              <h2 class="mb-4">Bewerbungsprozess</h2>
              <template v-if="activeLicense.state !=='closed'">
                <h6 class="mb-3">Der nächste Bewerbungszeitraum findet vom
                  {{ $filters.formatDate(activeLicense.published_from) }} bis
                  {{ $filters.formatDate(activeLicense.published_to) }} statt.</h6></template>
              <template v-else>
                <h6 class="mb-3">Der nächste Bewerbungszeitraum wird noch terminiert.</h6>
              </template>
              <div class="accordion mb-4">
                <div class="accordion-item">
                  <h5 class="accordion-header">
                    <button class="accordion-button" type="button"   :class="(activeAcc ==='desc') ? '' : 'collapsed'"  @click="activeAcc = 'desc'">Beschreibung</button>
                  </h5>
                  <div class="accordion-collapse collapse"  :class="(activeAcc ==='desc') ? 'show' : ''">
                    <div class="accordion-body" v-html="activeLicense.public_desc"></div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h5 class="accordion-header">
                    <button class="accordion-button" type="button"  :class="(activeAcc ==='cost') ? '' : 'collapsed'" @click="activeAcc = 'cost'">Lehrgangskosten</button>
                  </h5>
                  <div class="accordion-collapse collapse" :class="(activeAcc ==='cost') ? 'show' : ''">
                    <div class="accordion-body" v-html="activeLicense.public_cost"></div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h5 class="accordion-header"><button class="accordion-button" type="button"  :class="(activeAcc ==='dates') ? '' : 'collapsed'"  @click="activeAcc = 'dates'">Lehrgangstermine</button></h5>
                  <div class="accordion-collapse collapse"  :class="(activeAcc ==='dates') ? 'show' : ''">
                    <div class="accordion-body" v-html="activeLicense.public_dates"></div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h5 class="accordion-header"><button class="accordion-button" type="button"  :class="(activeAcc ==='basic') ? '' : 'collapsed'"  @click="activeAcc = 'basic'">Allgemeine Zulassungsvoraussetzungen</button></h5>
                  <div class="accordion-collapse collapse"  :class="(activeAcc ==='basic') ? 'show' : ''">
                    <div class="accordion-body" v-if="activeLicense.taskSplit.basic && activeLicense.taskSplit.basic.length > 0">
                    <template  v-for="public_basic in activeLicense.taskSplit.basic">
                      <h4>{{public_basic.name}}</h4>
                    <div v-if="public_basic.description_public" v-html="public_basic.description_public"></div>
                      <hr>
                    </template>
                    </div>
                  </div>
                </div><div class="accordion-item">
                <h5 class="accordion-header"><button class="accordion-button" type="button"  :class="(activeAcc ==='special') ? '' : 'collapsed'"  @click="activeAcc = 'special'">Besondere Zulassungsvoraussetzungen</button></h5>
                <div class="accordion-collapse collapse"  :class="(activeAcc ==='special') ? 'show' : ''">
                  <div class="accordion-body" v-if="activeLicense.taskSplit.special && activeLicense.taskSplit.special.length > 0">
                  <template   v-for="public_special in activeLicense.taskSplit.special">
                    <h4>{{public_special.name}}</h4>
                  <div v-if="public_special.description_public" v-html="public_special.description_public"></div>
                    <hr>
                  </template>
                  </div>
                </div>
              </div><div class="accordion-item">
                <h5 class="accordion-header"><button class="accordion-button" type="button"  :class="(activeAcc ==='check') ? '' : 'collapsed'"  @click="activeAcc = 'check'">Aufnahmeprüfverfahren</button></h5>
                <div class="accordion-collapse collapse"  :class="(activeAcc ==='check') ? 'show' : ''">
                  <div class="accordion-body" v-html="activeLicense.public_check"></div>
                </div>
              </div>
              </div>


            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserLogin from "@/components/UserLogin";
import MainHeader from "@/components/MainHeader.vue";
import axios from "axios";

export default {
  name: 'Vorraussetzungen',
  data() {
    return {
      activeLicense: null,
      licenses: [],
      activeAcc: 'desc'
    }
  },
  components: {
    MainHeader,
    UserLogin
  },
  created() {
    this.getLicenses();
  },
  methods: {
    getLicenses() {
      axios.get(this.$store.state.apiUrl + "plugins/dfb/licenses")
        .then(response => {
          this.licenses = response.data.result.data;
          if (this.$route.params.id) {
            this.activeLicense = this.licenses.find(license => license.alias === this.$route.params.id);
          } else {
            this.activeLicense = null;
          }
        })
    }
  },
  watch: {
    $route(to, from) {
      this.getLicenses();
    }
  }
}
</script>

<style lang="scss">
.item-page *{
  line-height: 1.5rem;
}
.item-page ul li {
  margin-bottom: 6px;
}
body .accordion-item{
  border: 0;
  border-radius: 0;
}
 body .accordion-button{
   background: #fff;
   color: #000;
   box-shadow: none;
   border-bottom: 1px solid #20ae80;
   border-radius: 0 !important;
   padding-top: 24px;
   padding-bottom: 20px;
 }
body .accordion-button:after{
  filter: invert(0);
}
body .accordion-button:not(.collapsed){
  box-shadow: none;
  background: #20ae80;
  color: #fff;
}
.accordion-button:not(.collapsed):after{
  filter: invert(1)  brightness(5);

}
body .accordion-button:focus{
  box-shadow: none;
}
h5.accordion-header{
  font-size: 16px;
  font-weight: bold;
}
.accordion-body h4 {
  line-height: 1.6rem;
  font-size: 20px;
  margin-bottom: 24px;
}
.card.bl.vor {
  cursor: pointer;
  border-left-width: 0 !important;
  .card-body {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    padding: 0 !important;
    position: relative;
    &:hover {
      h6 {
        background: #fff;
      }
    }
    h6 {
      width: 100%;
      transition: background-color 0.3s ease-in-out;
      background: rgba(255, 255, 255, 0.74);
      display: inline-block;
      text-align: center;
      padding-top: 12px;
      padding-bottom: 12px;
      position: absolute;
      bottom:0;
    }
  }
}

</style>
