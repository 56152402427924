<template>

<div class="container">

  <div class="row mt-3">

    <div class="col-12">

      <div class="card">

        <div class="card-body">

          <div class="row">
            <div class="col-lg-10"><h3 class="mb-3 mt-3 text-uppercase">{{ Task.name }}</h3></div>
            <div class="col-lg-2"><span class="mt-3 bagde text-white btn btn-disabled" style="cursor:inherit" disabled :class="$t('task.state.'+Task.status+'.bg')">Status: {{$t('task.state.'+Task.status+'.title')}}</span></div>

          </div>


          <div class="row">

<!--            <div class="col-12 col-md-6">-->

<!--              <span class="me-3"><font-awesome-icon :icon="['far', 'clock']" /></span> Erstellt: {{ $filters.formatDateTime(Task.ts_created) }}-->

<!--            </div>-->

<!--            <div class="col-12 col-md-6">-->

<!--              <template v-if="Task.due_date != '0000-00-00 00:00:00'">-->
<!--              <span class="me-3"><font-awesome-icon :icon="['far', 'clock']" /></span> Fällig: {{ Task.due_date }}-->
<!--              </template>-->

<!--            </div>-->

          </div>

          <div class="row mt-3 mb-3">

            <div class="col-12">


              <div class="description" v-html="addTargetBlank(Task.description)"></div>


            </div>

          </div>

          <div class="row mb-3">

            <div class="col-12">

              <table class="table w-100" v-if="(Task.type === 'upload' || Task.type === 'optional') && Task.files && Task.files.length > 0">
                <thead>
                <tr>
                  <th>Datei</th>
                  <th>Hochgeladen am</th>
                  <th class="text-center">Ansehen</th>
                  <th class="text-center">Löschen</th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="file in Task.files">
                    <td>{{file.title}}</td>
                    <td>{{$filters.formatDateTime(file.uploaded_on)}}</td>
                    <td class="text-center"> <span class="btn btn-success" @click="openFile(file)"><span v-if="loadingFile === file.id" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Ansehen</span> </td>
                    <td class="text-center"> <span class="btn btn-danger" @click="deleteFile(file)"><span v-if="deletingFile === file.id" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Löschen</span></td>
                  </tr>
                </tbody>
              </table>
              <Modal @click="activeFile = null" v-if="activeFile && activeFile.content">

                <div class="container">
                  <div class="card" style="padding:24px; height:80vh">
                    <perfect-scrollbar style="max-height: 80vh">
                      <vue-pdf-embed v-if="activeFile.contentType === 'application/pdf'" :source="'data:'+activeFile.contentType+';base64,'+activeFile.content" type="" />
                      <embed v-else :src="'data:'+activeFile.contentType+';base64,'+activeFile.content">
                    </perfect-scrollbar>
                  </div>
                </div>


              </Modal>

            </div>

          </div>
          <div class="row mb-3">

            <div class="col-12">
              <UploadComponent v-if="Task.type === 'upload' || Task.type === 'optional'" :task-id="currentTask" />
              <CheckTask v-if="Task.type === 'task' || Task.type === 'optional' " :task="Task" />
              <ChooseTask v-if="Task.type === 'choose'" :task="Task" />
              <router-link :to="{ name: 'ApplicationDetails' }" class="btn btn-secondary me-1 mb-3">Zurück zur Übersicht</router-link>

            </div>

          </div>

        </div>

      </div>



    </div>

  </div>

</div>

</template>

<script>

import axios from "axios";
import {mapGetters} from "vuex";
import UploadComponent from "@/components/UploadComponent";
import CheckTask from "@/components/CheckTask";
import ChooseTask from "@/components/ChooseTask";
import {ref} from "vue";
import VuePdfEmbed from 'vue-pdf-embed'
export default {

  name: 'TaskDetails',
  components: {UploadComponent, CheckTask, ChooseTask, VuePdfEmbed},
  data() {
    return {
      currentTask: null,
      Task: {},
      activeFile: null,
      loadingFile: null,
      deletingFile: null
    }
  },
  computed: {
    ...mapGetters(["getUser", "getApiUrl", "getApiAuth"])
  },

  methods: {

    openFile(file) {

      this.loadingFile = file.id;
      const headers = {"crmAuth":localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'customer/' +  this.getUser.crm_customer_id + "/readFile/" + file.id, {headers}).then((response) => {
        let shortReport = response.data.result;
        file.content = shortReport.data.content;
        file.contentType = shortReport.data.contentType;
        this.activeFile = file;
        this.loadingFile = null;
      }).catch((e) => {
        this.$log.debug(e);
        this.loadingFile = null;
      });
    },
    deleteFile(file) {

      this.deletingFile = file.id;
      const headers = {"crmAuth":localStorage.getItem("dfbToken")};
      axios.delete(this.getApiUrl + 'customer/' +  this.getUser.crm_customer_id + "/file/" + file.id, {headers}).then((response) => {

        this.deletingFile = null;
        const triggerReloadTask = new CustomEvent('triggerReloadTask');
        window.dispatchEvent(triggerReloadTask);
      }).catch((e) => {
        this.$log.debug(e);
        this.deletingFile = null;
        const triggerReloadTask = new CustomEvent('triggerReloadTask');
        window.dispatchEvent(triggerReloadTask);
      });
    },
    getTask() {
      this.$log.debug("triggered");
      const headers = {"crmAuth":localStorage.getItem("dfbToken")};
      let Vueinner = this;
      axios.get(this.getApiUrl + 'customer/' +  this.getUser.crm_customer_id + "/tasks/" + this.currentTask, {headers}).then((response) => {
        this.$log.debug(response);
        this.$log.debug(response);
        let shortReport = response.data.result;
        Vueinner.Task = shortReport.data;
      }).catch((e) => {
        this.$log.debug(e);
      });

    },
	  addTargetBlank(value) {

		  if (typeof value === "string") {
			  return value.replace(/<a/g, '<a target="_blank"');
		  }
		  return value;
	  },

  },


  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },
  created() {

    this.currentTask = this.$route.params.id;
    this.getTask();
    window.addEventListener('triggerReloadTask', this.getTask);

  },


  watch: {
    '$route.params.id' () {
      this.currentTask = this.$route.params.id;
      this.$log.debug("changed");
      this.getTask();
    }
  }

}

</script>
