<template>

  <MainHeader headline='Mein Profil' text='Verwalten Sie Ihr Profil' />
  <div class="container">
    <nav class="tabNav pb-0">
      <ul class="nav nav-tabs">

<!--        <li class="nav-item"><span class="nav-link" :class="(nav === 2) ? 'active' : ''" aria-current="page"-->
<!--                                   @click="showNav(2)">Spielererfahrung</span>-->
<!--        </li>-->
<!--        <li class="nav-item"><span class="nav-link" :class="(nav === 3) ? 'active' : ''" aria-current="page"-->
<!--                                   @click="showNav(3)">Aus- & Weiterbildung</span>-->
<!--        </li>-->
        <li class="nav-item"><span class="nav-link" :class="(nav === 4) ? 'active' : ''" aria-current="page"
                                   @click="showNav(4)">Persönliche Daten</span>
        </li>
        <li class="nav-item"><span class="nav-link" :class="(nav === 1) ? 'active' : ''" aria-current="page"
                                   @click="showNav(1)">Trainerlizenzen</span>
        </li>
      </ul>
    </nav>
<!--    <div class="contentTabs tab-1" v-show="nav === 1">-->
<!--      <ApplicationCoachExperiences :calc-types="calcTypes" />-->
<!--    </div>-->
<!--    <div class="contentTabs tab-2" v-show="nav === 2">-->
<!--      <ApplicationPlayerExperiences :calc-types="calcTypes" />-->
<!--    </div>-->

<!--    <div class="contentTabs tab-3" v-show="nav === 3">-->
<!--      <ApplicationEducations :calc-types="calcTypes" />-->
<!--    </div>-->

    <div class="contentTabs pt-0 tab-4" v-show="nav === 4">
      <div class="card p-1 p-lg-4" >
        <div class="card-body">
      <ProfileTab /></div>
      </div>
    </div>
    <div class="contentTabs pt-0  tab-1" v-show="nav === 1">

          <TrainerlizenzView />
    </div>

  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader'
import ApplicationCoachExperiences from "@/components/application/ApplicationCoachExperiences";
import ApplicationPlayerExperiences from "@/components/application/ApplicationPlayerExperiences";
import ApplicationEducations from "@/components/application/ApplicationEducations";
import ProfileTab from "@/components/ProfileTab";
import {mapGetters, mapMutations} from 'vuex';
import {useToast} from 'vue-toastification';
import axios from "axios";
import TrainerlizenzView from "@/views/TrainerlizenzView";
export default {
  name: 'ProfileView',
  components: {
    ProfileTab,
    ApplicationEducations,
    ApplicationPlayerExperiences,
    ApplicationCoachExperiences,
    MainHeader, TrainerlizenzView
  },
  computed: {
    ...mapGetters(["getUser"])
  },
  data() {
    return {
      nav: 4,
      calcTypes: {}
    }
  },
  created() {
    const toast = useToast();
    this.getCalcTypes();
    if (!this.getUser) {
      toast.warning("Bitte aktivieren Sie zunächst Ihre Bewerbung!");
      this.$router.push({name: 'applications'});
    }
  },
  methods: {
    showNav: function (index) {
      this.nav = index
    },
    getCalcTypes() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.getters.getApiUrl + 'plugins/dfb/calcTypes', {headers})
        .then(response => {
          let shortResponse = response.data.result;
          this.calcTypes = shortResponse.data;
        }).catch(error => {
        this.$log.debug(error)
      })
    }
  }
}
</script>
